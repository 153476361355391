<template>
    <div>
        <header-portrate />
        <div style="margin-bottom:70px !important;">
        <bread-crumbs :items="items" />
        
        <tabs-comp :tabs="tabs" />

        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
            <v-col
            cols="12"
            md="6"
            sm="12">
                <b-input-group class="mt-3">
                    <b-form-input placeholder="Start Date" style="border:1px solid #000;border-radius: 0;" type="date" v-model="sdate"></b-form-input>
                    <b-form-input placeholder="End Date" style="border:1px solid #000" type="date" v-model="edate"></b-form-input>
                    <b-input-group-append style="border:1px solid #000">
                        <b-button variant="light" style="background: gold !important;font-size:14px;" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                    </b-input-group-append>
                </b-input-group>
            </v-col>
            <!-- <v-col cols="12" md="7" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px;" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;display:none;background:red;color:#FFF" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
            </v-col> -->
        
            <v-col
            cols="12"
            md="12"
            class="mt-2"
            sm="12">
                <template>
                <v-data-table
                    :headers="headers"
                    :items="cardrows"
                    :items-per-page="10"
                    class="elevation-1"
                ></v-data-table>
                </template>
            </v-col>
    
    </v-row>
        
    </div>
    <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
    components:{BreadCrumbs, TabsComp,HeaderPortrate, Footer},
    data() {
        return {
            cardrows:[
               
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            modal1: false,
            active_tab:2,
            sdate:'',
            edate: '',
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function() {
            return [
                {text: this.lang.invoiceno , value:'invoice_number'},
                {text: this.lang.customer_name , value:'full_name'},
                {text: this.lang.inv_date , value:'inv_date'},
                {text: this.lang.total , value:'total'},
                {text: this.lang.vat , value:'vat'},
                {text: this.lang.ftotal , value:'ftotal'},
                {text: this.lang.return_date , value:'cancel_date'},
                {text: this.lang.return_case , value:'cancel_reson'},
                {text: this.lang.return_by , value:'cancel_by'}
            ]
        },
        items: function () {
            return {
                    text: this.lang.return_report,
                    disabled: true,
                    to: '/reports/return-reports',
                }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
    created() {
        this.getInvoices();
    },
    methods: {
        exportE(type){
            let url = '';
            if(type == 'e'){
                url = this.$store.state.SAMCOTEC.e_path;
            }else{
                url = this.$store.state.SAMCOTEC.pd_path;
            }
            const myhead = this.headers;
            const mydata = this.cardrows;
            const reportname = 'تقرير مرتجع المبيعات';
            const period = this.sdate + ' - ' + this.edate;
            const head = []
            const keys = []
            
            if(myhead.length > 0){
                for(let i = 0;i < myhead.length;i++){
                    head.push(myhead[i].text);
                    keys.push(myhead[i].value);
                }
            }
            const post = new FormData();
            post.append("export" , 'inrecepit');
            post.append("keys",keys);
            post.append("header",head);
            if(mydata.length > 0){
                for(let i = 0;i< mydata.length;i++){
                    for(const [key, value] of Object.entries(mydata[i])){
                       post.append('data['+i+']['+key+']',value);
                    }
                }
            }
            post.append("reportname",reportname);
            post.append("period",period);
            axios.post( url , post)
            .then((res) => {
                window.open('../api/'+res.data.url,'_blank');
            })
        },
        getInvoices(){
            const post = new FormData();
            post.append("type" , 'allInvoices');
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[sdate]",this.sdate);
            post.append("data[edate]",this.edate);
            post.append('data[type]',1);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data;
                this.cardrows = res;
            })
        }
    },
}
</script>